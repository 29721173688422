import * as React from 'react';
import { Box, Flex } from '@churni/styleguide';

import { routes, Router, Redirect, useLocation } from '@churni/common';

import { DashboardHeader } from '../DashboardLayout';
import { Webhooks } from './Webhooks';
import { ToggleEnvironment } from '../ToggleEnvironment';
import { FormIntegrations } from './FormIntegrations';
import { SEO } from '../SEO';

import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelHeaderLink
} from '../NewDashboard/Panel';

export function Integrations(): React.ReactElement {
  const { location } = useLocation();
  const pathname = location.pathname;

  return (
    <>
      <SEO title={'Integrations'} />
      <DashboardHeader title={'Integrations'}>
        <Flex flexDirection={['column', 'row']} alignItems="baseline">
          <Box mr={[0, 3]}>
            <ToggleEnvironment />
          </Box>
        </Flex>
      </DashboardHeader>
      <Panel>
        <PanelHeader>
          <PanelHeaderLink
            active={!!pathname.match(/^\/integrations\/webhooks/)}
            route={routes.webhooks()}
          >
            Webhooks
          </PanelHeaderLink>
          <PanelHeaderLink
            active={!!pathname.match(/^\/integrations\/widgets/)}
            route={routes.formIntegrations()}
          >
            Widgets
          </PanelHeaderLink>
        </PanelHeader>
        <PanelBody>
          <Router>
            <Webhooks path={'/webhooks'} />
            <FormIntegrations path={'/widgets'} />
            <Redirect from={'/'} to={routes.webhooks()} noThrow={true} />
          </Router>
        </PanelBody>
      </Panel>
    </>
  );
}
