import { firestore } from 'firebase';
import { genID, setDocument } from '../helpers';
import { Environment } from './Environment';

export interface WebhookSubscriptionInterface {
  id: string;
  companyID: string;
  url: string;
  type: 'webhook' | 'slack';
  event: string;
  token: string;
}

export const WebhookSubscription = {
  EVENT: {
    NEW_SESSION: 'new_session',
    SESSION_COMPLETED: 'session_completed'
  },

  create(
    form: Partial<WebhookSubscriptionInterface> = {}
  ): WebhookSubscriptionInterface {
    const defaultValues = {
      id: genID('sub'),
      companyID: '',
      url: '',
      event: '',
      type: 'webhook',
      token: ''
    };

    return {
      ...defaultValues,
      ...form
    };
  },
  queryByID(db: firestore.Firestore) {
    return (
      companyID: string,
      environmentID: string,
      subscriptionID: string
    ) => {
      return Environment.queryByID(db)(companyID, environmentID)
        .collection('subscriptions')
        .doc(subscriptionID);
    };
  },

  queryByEnvironment(db: firestore.Firestore) {
    return (companyID: string, environmentID: string) => {
      return Environment.queryByID(db)(companyID, environmentID).collection(
        'subscriptions'
      );
    };
  },

  /*
   * Create a subscription.
   */
  createSubscription(db: firestore.Firestore) {
    return async (
      companyID: string,
      environmentID: string,
      url: string,
      event: string
    ): Promise<WebhookSubscriptionInterface> => {
      const subscription = WebhookSubscription.create({
        companyID,
        url,
        event
      });

      await setDocument(
        WebhookSubscription.queryByID(db)(
          companyID,
          environmentID,
          subscription.id
        ),
        subscription
      );

      return subscription;
    };
  }
};
