/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import {
  Section,
  Box,
  Text,
  Button,
  Flex,
  Switch,
  SectionActions,
  Suspense
} from '@churni/styleguide';
import { Environment, useCompany } from '../containers';
import { useDatabase, useDocument } from '@churni/fire';
import { PublicData, Company } from '@churni/core';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

function FormIntegrationsContent(props: {
  data: any;
  environmentID: string;
}): React.ReactElement {
  const { environmentID } = props;

  const db = useDatabase();
  const company = useCompany();

  const publicData = useDocument(
    PublicData.query(db)(company.id, environmentID)
  );

  const [apps, setApps] = React.useState((publicData && publicData.apps) || {});
  const [hasChanged, setChanged] = React.useState(false);

  React.useEffect(() => {
    setApps((publicData && publicData.apps) || {});
  }, [environmentID, publicData]);

  const onSave = async () => {
    if (hasChanged) {
      await PublicData.query(db)(company.id, environmentID).set({
        ...(publicData || {}),
        apps
      });

      if (['todo', 'skip'].includes(company.onboarding.integrations)) {
        await Company.queryByID(db)(company.id).update({
          onboarding: {
            ...company.onboarding,
            integrations: 'done'
          }
        });
      }
    }

    setChanged(false);
  };

  const toggleApp = (app: string) => {
    setChanged(true);
    if (apps[app] && apps[app].enabled) {
      setApps({
        ...apps,
        [app]: {
          ...apps[app],
          enabled: false
        }
      });
      return;
    }

    const appID = window.prompt(`Enter your ${app} APP_ID:`);
    if (appID) {
      setApps({
        ...apps,
        [app]: {
          app_id: appID,
          enabled: true
        }
      });
    }
  };

  const {
    data: { crisp, intercom, drift }
  } = props;
  return (
    <Box>
      <ExternalApp
        className="intercom"
        title="Intercom"
        img={intercom}
        checked={apps.intercom && apps.intercom.enabled}
        onClick={() => toggleApp('intercom')}
      />
      <Box my={2}>
        <ExternalApp
          className="crisp"
          title="Crisp"
          img={crisp}
          checked={apps.crisp && apps.crisp.enabled}
          onClick={() => toggleApp('crisp')}
        />
      </Box>
      <ExternalApp
        className="drift"
        title="Drift"
        img={drift}
        checked={apps.drift && apps.drift.enabled}
        onClick={() => toggleApp('drift')}
        disabled
      />
      <SectionActions>
        <Box />
        <Button
          className="integrations-save"
          onClick={onSave}
          disabled={!hasChanged}
        >
          Save
        </Button>
      </SectionActions>
    </Box>
  );
}

export function ExternalApp(props: {
  title: string;
  img: string;
  checked: boolean;
  onClick: () => void;
  disabled?: boolean;
  className: string;
}): React.ReactElement {
  const { title, img, checked, onClick, disabled, className } = props;
  return (
    <Flex
      elevation={1}
      sx={{ borderRadius: 'rounded', opacity: disabled ? 0.5 : 1 }}
      justifyContent="space-between"
      alignItems="center"
      px={2}
      py={1}
    >
      <Flex alignItems="center">
        <Img fixed={img.childImageSharp.fixed} fadeIn={false} alt={title} />
        <Box ml={2}>
          <Text>{title}</Text>
        </Box>
      </Flex>
      <Switch
        className={className}
        checked={checked}
        onClick={onClick}
        disabled={disabled}
      />
    </Flex>
  );
}

export const FormIntegrations = () => {
  const { environmentID } = Environment.useContainer();

  return (
    <StaticQuery
      query={graphql`
        query {
          drift: file(relativePath: { eq: "logo/drift.png" }) {
            childImageSharp {
              fixed(width: 32, height: 32) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          crisp: file(relativePath: { eq: "logo/crisp.png" }) {
            childImageSharp {
              fixed(width: 32, height: 32) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          intercom: file(relativePath: { eq: "logo/intercom.png" }) {
            childImageSharp {
              fixed(width: 32, height: 32) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <Section title={'Customer messaging'}>
          <Suspense>
            <FormIntegrationsContent
              data={data}
              environmentID={environmentID}
            />
          </Suspense>
        </Section>
      )}
    />
  );
};
