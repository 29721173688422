import { firestore } from 'firebase';
import { Environment } from './Environment';

export interface PublicDataInterface {
  script: string;
  apps: { [key: string]: object };
}

export const PublicData = {
  create(data: Partial<PublicDataInterface> = {}): PublicDataInterface {
    const defaultValues = {
      script: ``,
      apps: {}
    };

    return {
      ...defaultValues,
      ...data
    };
  },
  query(db: firestore.Firestore) {
    return (companyID: string, environmentID: string) => {
      return Environment.queryByID(db)(companyID, environmentID)
        .collection('public')
        .doc('data');
    };
  }
};
