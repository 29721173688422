/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import {
  Box,
  Link,
  Button,
  FormGroup,
  Input,
  Text,
  Select,
  Icon,
  Section,
  Form,
  SectionActions,
  Suspense
} from '@churni/styleguide';
import isUrl from 'is-url';

import * as querystring from 'querystring';
import { useCompany, Environment } from '../containers';
import { useCollection, useDatabase } from '@churni/fire';
import { WebhookSubscription, setDocument } from '@churni/core';
import { slack } from '../../config';

export function Webhooks(props: { location: any }): React.ReactElement {
  const qs = querystring.parse(props.location.search.slice(1));
  const company = useCompany();
  const db = useDatabase();
  const { environmentID } = Environment.useContainer();

  React.useEffect(() => {
    if (qs.code) {
      (async () => {
        const params = {
          client_id: slack.clientID,
          client_secret: slack.clientSecret,
          code: qs.code,
          redirect_uri: 'https://app.churni.io/integrations/webhooks'
        };
        const search = querystring.stringify(params);
        const res = await fetch(
          `https://slack.com/api/oauth.v2.access?${search}`
        );

        const { ok, incoming_webhook, access_token } = await res.json();

        if (!ok) {
          return;
        }

        const webhook = WebhookSubscription.create({
          url: incoming_webhook.url,
          event: WebhookSubscription.EVENT.SESSION_COMPLETED,
          token: access_token,
          type: 'slack'
        });

        await setDocument(
          WebhookSubscription.queryByID(db)(company.id, qs.state, webhook.id),
          webhook
        );
      })();
    }
  }, [company.id, db, qs.code, qs.state]);

  return (
    <Box>
      <Suspense fallback={null}>
        <WebhooksList />
      </Suspense>

      <CreateWebhook />

      <Box py={3}>
        <Box ml="4px" mb={1}>
          <Text variant={'overline'}>Zapier</Text>
        </Box>
        <Box>
          <Link
            className="zapier-link"
            as={'a'}
            href="https://zapier.com/developer/public-invite/77747/8d500f93d4e74eca94306d30f2255b93/"
            target="__blank"
          >
            <Button className="zapier-link">Connect</Button>
          </Link>
        </Box>
        <Box mt={3} ml="4px" mb={1}>
          <Text variant={'overline'}>Slack</Text>
        </Box>
        <a
          href={`https://slack.com/oauth/v2/authorize?client_id=207709919990.990984052805&scope=incoming-webhook&state=${environmentID}`}
        >
          <img
            alt="Add to Slack"
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          />
        </a>
      </Box>
    </Box>
  );
}

export function CreateWebhook(): React.ReactElement {
  const { environmentID } = Environment.useContainer();
  const company = useCompany();
  const db = useDatabase();
  const [url, setURL] = React.useState('');
  const [event, setEvent] = React.useState('new_session');

  const createWebhook = async () => {
    const webhook = WebhookSubscription.create({
      url,
      event,
      type: 'webhook'
    });

    if (!url || !isUrl(url)) {
      return;
    }
    await setDocument(
      WebhookSubscription.queryByID(db)(company.id, environmentID, webhook.id),
      webhook
    );

    setURL('');
  };

  return (
    <Section title="Create a new webhook">
      <Form onSubmit={createWebhook}>
        <FormGroup title={'URL'} pb={2}>
          <Input
            value={url}
            onChangeValue={setURL}
            type="url"
            pattern="https?://.+"
            required
          />
        </FormGroup>
        <FormGroup title={'Event'}>
          <Select
            value={event}
            onChangeValue={setEvent}
            className="webhook-select-event"
          >
            <option value={'session_completed'}>Session Completed</option>
            <option value={'new_session'}>New Session</option>
          </Select>
        </FormGroup>
        <SectionActions>
          <Box />
          <Button type="submit" className="integrations-add-webhook">
            Add this webhook
          </Button>
        </SectionActions>
      </Form>
    </Section>
  );
}

export function WebhooksList(): React.ReactElement {
  const { environmentID } = Environment.useContainer();
  const company = useCompany();
  const db = useDatabase();
  const webhooks = useCollection(
    WebhookSubscription.queryByEnvironment(db)(company.id, environmentID)
  );

  const onDelete = async (subscriptionID: string) => {
    await WebhookSubscription.queryByID(db)(
      company.id,
      environmentID,
      subscriptionID
    ).delete();
  };

  if (!webhooks.length) {
    return null;
  }

  return (
    <Box mb={3}>
      <Section title="Webhooks">
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '150px 1fr 30px',
            gridRowGap: 1,
            gridColumnGap: 1
          }}
        >
          <Box>
            <b>Event</b>
          </Box>
          <Box>
            <b>URL</b>
          </Box>
          <Box />

          {webhooks.map(webhook => (
            <React.Fragment key={webhook.id}>
              <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {webhook.event}
              </Box>
              <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {webhook.url}
              </Box>
              <Box>
                <Button
                  variant={'text'}
                  icon={<Icon.X />}
                  onClick={() => onDelete(webhook.id)}
                />
              </Box>
            </React.Fragment>
          ))}
        </Box>
      </Section>
    </Box>
  );
}
